import React, { useState } from "react";

const ExcelUpload = ({ onUpload }) => {
  const [selectedMarket, setSelectedMarket] = useState("en");

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`http://localhost:5000/api/upload-rules/${selectedMarket}`, {
        method: "POST",
        headers: {
          "x-api-key": "your_secret_api_key", // Replace with your actual API key
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload file: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Uploaded Rules:", data);
      alert("Rules uploaded successfully!");

      if (onUpload) {
        onUpload(data.rules);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Upload Market-Specific Rules</h3>
      <label htmlFor="market-select" style={styles.label}>Select Market:</label>
      <select
        id="market-select"
        value={selectedMarket}
        onChange={(e) => setSelectedMarket(e.target.value)}
        style={styles.select}
      >
        <option value="en">English</option>
        <option value="da">Danish</option>
        <option value="fi">Finnish</option>
        <option value="is">Icelandic</option>
        <option value="no">Norwegian</option>
        <option value="sv">Swedish</option>
      </select>
      <br />
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} style={styles.fileInput} />
      
      <div style={styles.exampleContainer}>
        <h4 style={styles.exampleHeading}>Example File Format</h4>
        <p style={styles.exampleText}>
          Ensure your file has the following format before uploading:
        </p>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Market</th>
              <th style={styles.th}>Prohibited</th>
              <th style={styles.th}>Preferred</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.td}>en</td>
              <td style={styles.td}>Healthy meal</td>
              <td style={styles.td}>Balanced meal</td>
            </tr>
            <tr>
              <td style={styles.td}>en</td>
              <td style={styles.td}>Reverses wrinkles</td>
              <td style={styles.td}>Supports skin</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#333",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#555",
  },
  select: {
    padding: "8px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  fileInput: {
    margin: "10px 0",
  },
  exampleContainer: {
    marginTop: "20px",
    padding: "15px",
    borderRadius: "8px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
  },
  exampleHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#2c3e50",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
    borderRadius: "4px",
    overflow: "hidden",
    border: "1px solid #ddd",
  },
  th: {
    textAlign: "left",
    padding: "10px",
    borderBottom: "2px solid #3498db", // Blue underline for table headers
    backgroundColor: "#ecf0f1", // Light gray for table headers
    fontSize: "14px",
    fontWeight: "bold",
    color: "#34495e",
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
    color: "#2c3e50",
  },
  exampleText: {
    fontSize: "14px",
    color: "#555",
    lineHeight: "1.6",
    marginBottom: "10px",
  },
};

export default ExcelUpload;
