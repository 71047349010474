const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://claimsback.herbieterms.com";
const API_KEY = process.env.REACT_APP_API_KEY || "your_secret_api_key";

/**
 * Analyzes the given text for compliance using market-specific rules and OpenAI general analysis.
 * @param {string} text - The text to be analyzed.
 * @param {string} language - The language/market to be used for analysis.
 * @returns {Promise<{marketIssues: Array, generalAnalysis: string}>} - The compliance results.
 */
export const analyzeText = async (text, language) => {
  try {
    // Ensure API key is available
    if (!API_KEY) {
      throw new Error("Missing API key. Please configure your API key.");
    }

    // Fetch the authentication token from cookies or localStorage
    const authToken = localStorage.getItem("authToken") || getCookie("authToken");

    if (!authToken) {
      throw new Error("Unauthorized: Authentication token is missing.");
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_KEY,
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ text, language }),
    };

    // Perform the fetch with retry logic
    const response = await retryFetch(`${API_BASE_URL}/api/analyze`, options, 3);

    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Backend error: ${response.status} ${errorDetails}`);
    }

    const data = await response.json();

    // Validate the backend response structure
    if (!data || !Array.isArray(data.marketIssues) || typeof data.generalAnalysis !== "string") {
      throw new Error("Invalid response format from backend.");
    }

    return { marketIssues: data.marketIssues, generalAnalysis: data.generalAnalysis };
  } catch (error) {
    console.error("Error analyzing text:", error);
    throw new Error(`Analysis failed: ${error.message}`);
  }
};

/**
 * Utility function to get a cookie by name.
 * @param {string} name - The name of the cookie.
 * @returns {string|null} - The cookie value or null if not found.
 */
const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? decodeURIComponent(match[2]) : null;
};

/**
 * Fetch with a timeout to prevent hanging requests.
 * @param {string} url - The URL to fetch.
 * @param {object} options - The fetch options.
 * @param {number} timeout - The timeout in milliseconds.
 * @returns {Promise<Response>} - The fetch response.
 */
const fetchWithTimeout = (url, options, timeout = 20000) =>
  Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error("Request timed out")), timeout)),
  ]);

/**
 * Retry wrapper for fetch with timeout.
 * @param {string} url - The URL to fetch.
 * @param {object} options - The fetch options.
 * @param {number} retries - Number of retry attempts.
 * @returns {Promise<Response>} - The fetch response after retries.
 */
const retryFetch = async (url, options, retries = 3) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await fetchWithTimeout(url, options, 15000); // 15-second timeout for each attempt
    } catch (error) {
      console.error(`Attempt ${attempt} failed:`, error.message);
      if (attempt === retries) {
        // Throw error if retries are exhausted
        throw new Error(`All retry attempts failed: ${error.message}`);
      }
    }
  }
};
