import React, { useState } from "react";

const TextInput = ({ onSubmit }) => {
  const [text, setText] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim()) {
      onSubmit(text);
      setText(""); // Clear the text area after submission
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Enter Your Text</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <textarea
          style={styles.textarea}
          rows="6"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Paste your text here..."
        />
        <button type="submit" style={styles.button}>
          Check Compliance
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    marginBottom: "20px",
  },
  heading: {
    fontSize: "18px",
    marginBottom: "10px",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  textarea: {
    width: "100%", // Full width of the container
    height: "150px", // Taller for better visibility
    padding: "10px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    resize: "vertical", // Allow vertical resizing
    fontFamily: "Arial, sans-serif",
    marginBottom: "10px", // Spacing between textarea and button
  },
  button: {
    padding: "10px 20px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    cursor: "pointer",
    alignSelf: "flex-start",
  },
};

export default TextInput;
