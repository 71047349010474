import React, { useState } from "react";
import TextInput from "./components/TextInput";
import ComplianceChecker from "./components/ComplianceChecker";
import ExcelUpload from "./components/ExcelUpload";
import { analyzeText } from "./services/openAIService";

const App = () => {
  const [inputText, setInputText] = useState(""); // Text input by the user
  const [complianceResult, setComplianceResult] = useState(""); // General analysis result
  const [marketIssues, setMarketIssues] = useState([]); // Market-specific issues
  const [selectedMarket, setSelectedMarket] = useState("en"); // Default market: English
  const [loading, setLoading] = useState(false); // Loading state
  const [uploadedRules, setUploadedRules] = useState([]); // In-memory uploaded rules

  const handleTextSubmit = async (text) => {
    setInputText(text);
    setLoading(true); // Show loading indicator

    try {
      const { marketIssues, generalAnalysis } = await analyzeText(text, selectedMarket);
      setMarketIssues(marketIssues || []); // Update market-specific issues
      setComplianceResult(generalAnalysis || ""); // Ensure it's a string
    } catch (error) {
      console.error("Error analyzing text:", error.message);
      setMarketIssues([]);
      setComplianceResult("Error during compliance analysis. Please try again.");
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const handleExcelUpload = (uploadedPairs) => {
    setUploadedRules([...uploadedRules, ...uploadedPairs]);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Compliance Checker</h1>

      {/* Text Input with Market Selector */}
      <div style={styles.section}>
        <label htmlFor="market-select" style={styles.label}>
          Target Market:
        </label>
        <select
          id="market-select"
          value={selectedMarket}
          onChange={(e) => setSelectedMarket(e.target.value)}
          style={styles.select}
        >
          <option value="en">English</option>
          <option value="da">Danish</option>
          <option value="fi">Finnish</option>
          <option value="is">Icelandic</option>
          <option value="no">Norwegian</option>
          <option value="sv">Swedish</option>
        </select>

        <TextInput onSubmit={handleTextSubmit} />
      </div>

      {/* Loading Indicator */}
      {loading && (
        <div style={styles.loadingContainer}>
          <div style={styles.spinner}></div>
          <p style={styles.loadingText}>Analyzing text, please wait...</p>
        </div>
      )}

      {/* Compliance Results */}
      {!loading && (
        <ComplianceChecker
          complianceResult={complianceResult}
          marketIssues={marketIssues}
          inputText={inputText}
        />
      )}

      {/* File Upload Section */}
      <div style={styles.section}>
        <h3 style={styles.subHeading}>Upload Market-Specific Rules</h3>
        <ExcelUpload onUpload={handleExcelUpload} />
      </div>

      {/* Uploaded Rules Display */}
      {uploadedRules.length > 0 && (
        <div style={styles.section}>
          <h4 style={styles.subHeading}>Uploaded Market-Specific Rules</h4>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Prohibited</th>
                <th style={styles.th}>Preferred</th>
              </tr>
            </thead>
            <tbody>
              {uploadedRules.map((rule, index) => (
                <tr key={index}>
                  <td style={styles.td}>{rule.prohibited}</td>
                  <td style={styles.td}>{rule.preferred}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// Inline styling for simplicity
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  title: {
    textAlign: "center",
    color: "#333",
  },
  section: {
    marginBottom: "20px",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
  },
  label: {
    marginRight: "10px",
    fontWeight: "bold",
  },
  select: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginBottom: "15px",
  },
  loadingContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  spinner: {
    border: "4px solid #f3f3f3",
    borderTop: "4px solid #3498db",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
    margin: "0 auto",
  },
  loadingText: {
    marginTop: "10px",
    fontSize: "14px",
    color: "#555",
  },
  subHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#34495e",
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  th: {
    textAlign: "left",
    padding: "10px",
    borderBottom: "2px solid #3498db",
    backgroundColor: "#ecf0f1",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#34495e",
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
    color: "#2c3e50",
  },
};

export default App;
