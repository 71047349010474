import React from "react";

const ComplianceChecker = ({ complianceResult, marketIssues, inputText }) => {
  const renderMarketIssues = () => {
    if (!marketIssues || marketIssues.length === 0) {
      return <p style={styles.noIssues}>No market-specific issues found.</p>;
    }

    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Prohibited Term</th>
            <th style={styles.th}>Preferred Term</th>
          </tr>
        </thead>
        <tbody>
          {marketIssues.map((issue, index) => (
            <tr key={index}>
              <td style={styles.td}>{issue.prohibited}</td>
              <td style={styles.td}>{issue.preferred}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderGeneralAnalysis = () => {
    if (!complianceResult || typeof complianceResult !== "string") {
      return <p style={styles.noAnalysis}>No general analysis available.</p>;
    }
  
    const adviceLines = complianceResult.split("\n").filter((line) => line.trim());
  
    return (
      <ul style={styles.list}>
        {adviceLines.map((line, index) => (
          <li key={index} style={styles.listItem}>
            {line}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div style={styles.card}>
      <h2 style={styles.heading}>Compliance Results</h2>
      <p style={styles.text}>
        <strong>Input Text:</strong> {inputText || "No input provided."}
      </p>

      <h3 style={styles.subHeading}>Market-Specific Issues</h3>
      {renderMarketIssues()}

      <h3 style={styles.subHeading}>General Analysis</h3>
      {renderGeneralAnalysis()}
    </div>
  );
};

const styles = {
  card: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  },
  heading: {
    fontSize: "18px",
    marginBottom: "10px",
    color: "#333",
  },
  subHeading: {
    fontSize: "16px",
    marginTop: "15px",
    marginBottom: "10px",
    color: "#555",
  },
  text: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "10px",
  },
  noIssues: {
    fontSize: "14px",
    color: "#43a047",
    fontWeight: "bold",
  },
  noAnalysis: {
    fontSize: "14px",
    color: "#555",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
  },
  th: {
    textAlign: "left",
    padding: "10px",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f4f4f4",
    fontSize: "14px",
  },
  td: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
    color: "#555",
  },
  list: {
    listStyleType: "disc",
    marginLeft: "20px",
    marginTop: "10px",
  },
  listItem: {
    marginBottom: "5px",
    fontSize: "14px",
    color: "#555",
  },
};

export default ComplianceChecker;
